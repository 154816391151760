import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És de grans dimensions, fins a 45 cm de llarg, 30 d’ample i 12 de gruixa, en forma de mènsula, sense peu. La part superior és llanosa i irregular, de color marró negrós i els exemplars vells presentan una mena de crosta fina. El marge és blanquinós o groguenc, ondulat i quan és jove presenta unes gotetes de líquid. A la part inferior es troba l’himeni format per tubs llargs de color fosc. Els porus són petits i angulosos i poden presentar unes gotes de líquid transparent. L’himeni pot estar cobert per una mena de pruïna blanquinosa més visible als exemplars vells. Les espores són blanquinoses, després groguenques en massa, d’ovoides a quasi globoses, de 6-8 x 5,5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      